import firebase from "firebase";
const config = {
  apiKey: "AIzaSyAqo79WuR7R5RQRTPlwU8813hynvvQ9JFI",
  authDomain: "bluemark-e8734.firebaseapp.com",
  databaseURL: "https://bluemark-e8734.firebaseio.com",
  projectId: "bluemark-e8734",
  storageBucket: "bluemark-e8734.appspot.com",
  messagingSenderId: "333998005324",
  appId: "1:333998005324:web:d3150d4ec2dda75d62e8e9"
};

firebase.initializeApp(config);
export default firebase;
export const fireauth = firebase.auth();
export const firestore = firebase.firestore();
